<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="120px"
    >
      <el-form-item label="部门" prop="departmentId">
        <el-select
          v-model="dataForm.departmentId"
          placeholder="请选择部门"
          style="width: 100%"
          clearable
          filterable
          @change="selectDepartment"
        >
          <el-option
            v-for="item in departmentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="班组" prop="teamId">
        <el-select
          v-model="dataForm.teamId"
          placeholder="请选择班组"
          style="width: 100%"
          clearable
          filterable
          @focus="teamFocus"
          @change="selectTeam"
        >
          <el-option
            v-for="item in teamList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="负责人" prop="chargeId">
        <el-select
          v-model="dataForm.chargeId"
          clearable
          filterable
          style="width: 100%"
          placeholder="请选择负责人"
          @change="chargeIdChange"
        >
          <el-option
            v-for="item in allEmployeeList"
            :key="item.id"
            :label="item.name + '(' + item.departmentName + ')'"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="员工" prop="renyuanId">
        <span v-if="!dataForm.teamId" style="color: #999">(请先选择班组)</span>
        <el-button v-else type="success" size="mini" @click="openSelectEmployee"
          >选择员工</el-button
        >
        <div>
          <span
            style="margin-right: 16px"
            v-for="item of selectEmployeeList"
            :key="item"
            >{{ item }}</span
          >
        </div>
      </el-form-item>
      <el-form-item
        v-if="dataForm.isConflict == 1"
        label="重叠界面责任划分方式"
        prop="conflictSolve"
      >
        <el-input
          v-model="dataForm.conflictSolve"
          placeholder="重叠界面责任划分方式"
          type="textarea"
          rows="6"
        ></el-input>
      </el-form-item>
      <el-form-item label="基本职责" prop="jbzz">
        <el-input
          v-model="dataForm.jbzz"
          placeholder="基本职责"
          type="textarea"
          rows="6"
        ></el-input>
      </el-form-item>
      <el-form-item label="其它职责" prop="qtzz">
        <el-input
          v-model="dataForm.qtzz"
          placeholder="其它职责"
          type="textarea"
          rows="6"
        ></el-input>
      </el-form-item>
      <el-form-item label="管辖范围" prop="gxfw">
        <el-input
          v-model="dataForm.gxfw"
          placeholder="管辖范围"
          type="textarea"
          rows="6"
        ></el-input>
      </el-form-item>
      <el-form-item label="业务" prop="yewu">
        <el-input
          v-model="dataForm.yewu"
          placeholder="业务"
          type="textarea"
          rows="6"
        ></el-input>
      </el-form-item>
      <el-form-item label="区域" prop="quyu">
        <el-input
          v-model="dataForm.quyu"
          placeholder="区域"
          type="textarea"
          rows="6"
        ></el-input>
      </el-form-item>
      <el-form-item label="设备" prop="shebei">
        <el-input
          v-model="dataForm.shebei"
          placeholder="设备"
          type="textarea"
          rows="6"
        ></el-input>
      </el-form-item>

      <el-form-item label="生效时间" prop="effectiveDate">
        <el-date-picker
          style="width: 100%"
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="dataForm.effectiveDate"
          type="date"
          placeholder="选择生效时间"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>

    <el-dialog
      title="选择员工"
      :visible.sync="showSelectEmployee"
      width="40%"
      append-to-body
    >
      <div>
        <el-input
          v-model="searchInputEmployee"
          placeholder="请输入内容筛选"
          style="width: 220px"
          clearable
        ></el-input>
        <el-select
          v-model="searchselectEmployee"
          placeholder="请选择"
          style="margin-left: 10px"
        >
          <el-option
            v-for="item in ['全部', '未分配']"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <el-button
          type="success"
          style="margin-left: 10px"
          @click="searchEmployee"
          >搜索</el-button
        >
      </div>
      <el-checkbox-group v-model="selectEmployeeList">
        <el-checkbox
          style="margin-top: 8px"
          v-for="item of departmentEmployeeList"
          :key="item.id"
          :label="item.name"
        ></el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelSelectEmployee">取 消</el-button>
        <el-button type="primary" @click="confirmSelectEmployee"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      searchInputEmployee: "",
      searchselectEmployee: "全部",
      showSelectEmployee: false,
      visible: false,
      selectEmployeeList: [], // 已选中的员工列表
      oldSelectEmployeeList: [],
      departmentList: [], // 部门列表
      teamList: [], // 班组列表
      employeeList: [], //员工列表
      allEmployeeList: [], // 所有员工列表
      departmentEmployeeList: [], // 部门所有员工列表
      dataForm: {
        id: 0,
        departmentId: "",
        departmentName: "",
        teamId: "",
        teamName: "",
        chargeId: "",
        chargeName: "",
        jbzz: "",
        qtzz: "",
        gxfw: "",
        yewu: "",
        quyu: "",
        shebei: "",
        renyuanId: "",
        renyuanName: "",
        effectiveDate: "",
        conflictSolve: "",
        isConflict: "",
        createDate: "",
        updateDate: "",
        isDeleted: "",
        conflictTeamId: "", // 冲突的班组id
      },
      dataRule: {
        departmentId: [
          { required: true, message: "部门不能为空", trigger: "blur" },
        ],
        teamId: [{ required: true, message: "班组不能为空", trigger: "blur" }],
        chargeName: [
          { required: true, message: "负责人不能为空", trigger: "blur" },
        ],
        jbzz: [
          { required: true, message: "基本职责不能为空", trigger: "blur" },
        ],
        qtzz: [
          { required: true, message: "其它职责不能为空", trigger: "blur" },
        ],
        gxfw: [
          { required: true, message: "管辖范围不能为空", trigger: "blur" },
        ],
        yewu: [{ required: true, message: "业务不能为空", trigger: "blur" }],
        quyu: [{ required: true, message: "区域不能为空", trigger: "blur" }],
        shebei: [{ required: true, message: "设备不能为空", trigger: "blur" }],
        effectiveDate: [
          { required: true, message: "生效时间不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    async searchEmployee() {
      let arr = [];
      console.log("开始执行");
      await this.getDepartmentEmployeeList();
      if (this.searchselectEmployee == "全部" && this.searchInputEmployee) {
        this.departmentEmployeeList.forEach((item) => {
          if (item.name.indexOf(this.searchInputEmployee) != -1) {
            arr.push(item);
          }
        });
        this.departmentEmployeeList = JSON.parse(JSON.stringify(arr));
      } else if (
        this.searchselectEmployee == "未分配" &&
        !this.searchInputEmployee
      ) {
        this.departmentEmployeeList.forEach((item) => {
          if (item.name.indexOf("未分配") != -1) {
            arr.push(item);
          }
        });
        this.departmentEmployeeList = JSON.parse(JSON.stringify(arr));
      } else if (
        this.searchselectEmployee == "未分配" &&
        this.searchInputEmployee
      ) {
        this.departmentEmployeeList.forEach((item) => {
          if (
            item.name.indexOf("未分配") != -1 &&
            item.name.indexOf(this.searchInputEmployee) != -1
          ) {
            arr.push(item);
          }
        });
        this.departmentEmployeeList = JSON.parse(JSON.stringify(arr));
      }
      console.log("执行完毕");
    },
    // 取消选择员工
    cancelSelectEmployee() {
      this.selectEmployeeList = this.oldSelectEmployeeList;
      this.showSelectEmployee = false;
    },
    // 确定选择员工
    confirmSelectEmployee() {
      this.dataForm.isConflict = 0;
      this.dataForm.conflictTeamId = "";
      if (this.selectEmployeeList && this.selectEmployeeList.length > 0) {
        let idArr = [];

        this.selectEmployeeList.forEach((item) => {
          this.departmentEmployeeList.forEach((departmentEmployee) => {
            if (item == departmentEmployee.name) {
              idArr.push(departmentEmployee.id);

              // 选择了别的班组员工
              if (departmentEmployee.gridTeamLog) {
                if (
                  departmentEmployee.gridTeamLog.teamId != this.dataForm.teamId
                ) {
                  this.dataForm.conflictTeamId =
                    departmentEmployee.gridTeamLog.teamId;
                  this.dataForm.isConflict = 1;
                }
              }
            }
          });
        });
        this.dataForm.renyuanName = this.selectEmployeeList.join("_");

        this.dataForm.renyuanId = idArr.join("_");
      } else {
        this.selectEmployeeList = [];
        this.dataForm.renyuanId = "";
      }
      this.showSelectEmployee = false;
    },
    async getDepartmentEmployeeList() {
      let res = await this.$http.gridteam.employeeList({
        did: this.dataForm.departmentId,
      });
      if (res.data && res.data.length > 0) {
        console.log("请求完毕");
        res.data.forEach((item) => {
          item.name = item.name + "(" + item.shenfenzheng + ")";
          if (item.gridTeamLog) {
            item.name = item.name + "(" + item.gridTeamLog.teamName + ")";
          } else {
            item.name = item.name + "(未分配)";
          }

          // 筛选之前已选中的
          if (this.dataForm.renyuanId) {
            let renyuanIdArr = this.dataForm.renyuanId.split("_");
            renyuanIdArr.forEach((renyuanId) => {
              if (item.id == renyuanId) {
                this.selectEmployeeList.push(item.name);
              }
            });
          }
        });
        this.departmentEmployeeList = res.data;
      }
    },
    // 打开员工选择框
    openSelectEmployee() {
      this.departmentEmployeeList = [];
      this.oldSelectEmployeeList = this.selectEmployeeList;
      this.selectEmployeeList = [];
      this.getDepartmentEmployeeList();

      this.showSelectEmployee = true;
    },
    // 获取所有员工
    getAllEmployee() {
      this.$http.employee.list({ limit: 99999, page: 1 }).then((res) => {
        if (res && res.code == 0) {
          this.allEmployeeList = res.data.list;
        }
      });
    },
    // 选择的负责人发生变化
    chargeIdChange(e) {
      this.dataForm.chargeName = "";
      if (e) {
        // 获取当前选择的员工名字
        let result = this.allEmployeeList.filter((ele) => {
          return ele.id == e;
        });
        if (result.length > 0) {
          this.dataForm.chargeName = result[0].name;
        }
      }
    },
    // 获取班组下的所有员工
    getEmployee() {
      this.$http.employee
        .list({ limit: 99999, page: 1, tid: this.dataForm.teamId })
        .then((res) => {
          if (res && res.code == 0) {
            this.employeeList = res.data.list;
          }
        });
    },
    // 选择部门
    selectDepartment(value) {
      this.departmentList.forEach((item) => {
        if (item.id == value) {
          this.dataForm.departmentName = item.name;
        }
      });
    },
    // 选中班组
    selectTeam(value) {
      this.teamList.forEach((item) => {
        if (item.id == value) {
          this.dataForm.teamName = item.name;
          if (item.employee) {
            this.dataForm.chargeId = item.employee.id;
            this.dataForm.chargeName = item.employee.name;
          }
        }
      });
      this.getEmployee();
    },
    // 获取班组列表
    getTeamList() {
      this.$http.team
        .list({ limit: 99999, page: 1, did: this.dataForm.departmentId })
        .then((res) => {
          if (res && res.code == 0) {
            this.teamList = res.data.list;
          }
        });
    },
    // 班组选择框获得焦点
    teamFocus() {
      if (!this.dataForm.departmentId) {
        this.$message.error("请先选择部门");
        return;
      }
      this.getTeamList();
    },
    // 获取部门列表
    getDepartmentList() {
      this.$http.department.list({ limit: 9999, page: 1 }).then((res) => {
        if (res && res.code == 0) {
          this.departmentList = res.data.list;
        }
      });
    },
    init(id) {
      this.selectEmployeeList = [];
      this.employeeList = [];
      this.getDepartmentList();
      this.getAllEmployee();

      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.id) {
          this.$http.gridteam.info({ id: this.dataForm.id }).then((data) => {
            if (data && data.code === 0) {
              this.dataForm = data.data;
              this.getTeamList();
              this.getEmployee();
              if (this.dataForm.renyuanName) {
                this.selectEmployeeList = this.dataForm.renyuanName.split("_");
              }
            }
          });
        }
      });
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let requestDate = this.dataForm;

          if (this.dataForm.id) {
            // 修改
            this.$http.gridteam.update(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "修改操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          } else {
            // 新增
            requestDate.id = "";
            this.$http.gridteam.save(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>
